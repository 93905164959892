:root {
  --mail-icon-size: 30px;
  --mail-icon-sice-hover: 30px;
  --mail-ico-size-widescreen: 5vh;
  --margin-container-tablet: 1em;
  --mission-margin-lr-tablet: 1em .5em;
  --container-mission-width-tablet: 70vw;
  --mission-font-size-tabler: 1.5vmax;
  --logo-size-tablet: 20vh;
  --margin-container-mobile: 0vmax;
  --mission-margin-lr-mobile: 4vmax;
  --container-mission-width-mobile: 50vw;
  --logo-size-mobile: 20vh;
  --logo-size: 26vh;
  --logo-size-widescreen: 26vh;
  --title-font-size: 6vmax;
  --mission-font-size: 2vmax;
  --mission-font-size-tablet: 3vmax;
  --mission-font-size-mobile: 2.5vmax;
  --title-font-weight: 520;
  --subtitle-font-weight: 600;
  --mission-font-weight: 500;
  --title-container-width: 10vh;
  --mission-container-width: 5vh;
  --gap-1: 5vmax;
  --margin-container: 1.5vmax;
  --margin-top-logo: 4vmax;
  --max-width-mission: 50vmax;
  --copy-window-position: 2vh;
  --primary-background: #e4e2e2;
  --secondary-background: #0f1029;
  --primary-background-opacity: 0, 0, 0;
  --second-background-opacity: 0, 0, 0;
  --opacity-percent: .4;
  --primary-color: #e2e2e2;
  --secondary-color: #0f1029;
  --gradient-percent: .4;
  --margin-container-mobile: 2vh 0vh;
  --screen-ratio: 16 / 9;
  --button-font-size: 20px;
  --button-container-width: 50vh;
  --button-width: 80vw;
  --button-width-desktop: 45vh;
  --button-font-weight: 600;
  --button-font-weight-tablet: 800;
}

html {
  height: 100vh;
  font-size: 1rem;
}

body {
  background: radial-gradient(circle at center, var(--secondary-color), var(--primary-background)) var(--primary-background);
  color: var(--primary-color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Nunito, sans-serif;
  display: flex;
  overflow: hidden;
}

footer {
  color: var(--secondary-color);
  margin-bottom: 50px;
}

.section__container {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 95vh;
  margin-top: 5vh;
  display: flex;
}

.div__container {
  margin: var(--margin-container);
  background: var(--secondary-background);
  border-radius: 3vmax;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  height: 78%;
  max-height: 90vh;
  padding-bottom: 10%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.container__logo {
  padding-top: var(--margin-top-logo);
  justify-content: center;
  align-items: center;
  display: flex;
}

.img__logo {
  width: var(--logo-size);
}

.main__page {
  width: 100%;
  height: 100%;
  display: flex;
}

.icon-mail {
  align-items: center;
}

.container__title {
  font-size: var(--title-font-size);
  font-weight: var(--title-font-weight);
  text-align: center;
  color: var(--primary-color);
  margin: var(--margin-container);
}

.paragraph__text {
  font-size: var(--mission-font-size);
}

.container__mission {
  text-align: justify;
  font-size: var(--mission-font-size);
  margin: var(--margin-container);
  min-width: 30vmax;
  max-width: 60vmax;
}

figure {
  padding-top: .2vmax;
}

#privacy {
  opacity: 1;
}

#figure__privacy {
  flex-direction: row-reverse;
  display: flex;
}

#privacy-hover {
  opacity: 0;
}

#figure__mail {
  flex-direction: row-reverse;
  display: flex;
}

#mail {
  opacity: 1;
}

#mail-hover {
  opacity: 0;
}

@media screen and (aspect-ratio >= 13 / 9) {
  .div__container {
    margin: var(--margin-container);
    align-items: center;
    display: flex;
  }

  .container__logo {
    width: 35%;
  }

  .img__logo {
    height: var(--logo-size-widescreen);
  }

  .container__title {
    font-size: 8.8vh;
  }

  .container__mission {
    width: var(--container-mission-width-mobile);
    font-size: 3vh;
  }

  #copyright {
    position: auto;
  }

  .logo__mail {
    height: var(--mail-icon-size);
  }

  .logo__mail-hover {
    height: var(--mail-icon-size-hover);
  }

  .logo__privacy {
    height: var(--mail-icon-size);
  }

  .logo__privacy-hover {
    height: var(--mail-icon-size-hover);
  }
}

@media (width <= 768px) {
  body {
    overflow: hidden;
  }

  .section__container {
    align-items: flex-start;
    display: flex;
  }

  .img__logo {
    height: var(--logo-size-tablet);
  }

  .container__mission {
    font-size: var(--mission-font-size-tablet);
    margin: var(--mission-margin-lr-tablet);
    text-align: center;
    width: var(--container-mission-width-tablet);
  }

  .div__container {
    margin: var(--margin-container-tablet);
    height: 85%;
  }

  #copyright {
    position: fixed;
    bottom: 15px;
    left: 30%;
  }
}

@media (width <= 480px) {
  .container__mission {
    margin: var(--mission-margin-lr-mobile);
    font-size: var(--mission-font-size-mobile);
    width: var(--container-mission-width-mobile);
    height: 100vh;
  }

  body {
    justify-content: flex-start;
    display: flex;
  }

  #copyright {
    position: fixed;
    bottom: 15px;
    left: 30%;
  }

  .img__logo {
    height: var(--logo-size-mobile);
  }
}

.container__button {
  width: var(--button-width);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: .5em;
  margin-top: 25px;
  display: flex;
}

button {
  background: var(--primary-color);
  color: var(--secondary-color);
  border: .5vw solid var(--secondary-color);
  width: var(--button-width);
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  border-radius: 3vmax;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  height: 7vh;
  padding: 1vh 10% 1vh .8vw;
  transition: transform .3s, box-shadow .3s;
  display: flex;
}

button:hover {
  background: var(--secondary-background);
  color: var(--primary-color);
  border: .25vw solid var(--primary-color);
  transform: scale(1.1);
  box-shadow: 0 1.6vw 4.9vw #0003;
}

a {
  text-decoration: none;
}

.button__contact-us {
  cursor: pointer;
  cursor: pointer;
  align-items: center;
  min-width: 260px;
  max-width: 400px;
  font-size: 25px;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.button__contact-us > .inter__button {
  padding-right: 10px;
}

.button__text-privacy {
  cursor: pointer;
  font-size: var(--button-font-size);
  text-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.button__text-privacy > .inter__button {
  padding-right: 10px;
}

.button__text-contact-us {
  cursor: pointer;
  font-size: var(--button-font-size);
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.link__button {
  text-decoration: none;
}

.button__privacy {
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 260px;
  max-width: 400px;
  padding-left: 30px;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.button__mail {
  cursor: pointer;
  align-items: center;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.logo__privacy, .logo__mail {
  height: var(--mail-icon-size);
  margin-right: 1vw;
}

.inter__button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5em;
  display: flex;
}

.inter__button > article {
  padding-bottom: 3px;
  font-size: 22px;
  font-weight: 300;
}

.inter__button > div {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.button__text {
  padding-bottom: .2vmax;
}

/*# sourceMappingURL=index.4f212c08.css.map */
