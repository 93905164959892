/*# sourceMappingURL=globalStyles.css.map */
:root {
	--mail-icon-size: 30px;
	--mail-icon-sice-hover: 30px;
	--mail-ico-size-widescreen: 5vh;
	--margin-container-tablet: 1em;
	--mission-margin-lr-tablet: 1em 0.5em;
	--container-mission-width-tablet: 70vw;
	--mission-font-size-tabler: 1.5vmax;
	--logo-size-tablet: 20vh;
	--margin-container-mobile: 0vmax;
	--mission-margin-lr-mobile: 4vmax;
	--container-mission-width-mobile: 50vw;
	--logo-size-mobile: 20vh;
	--logo-size: 26vh;
	--logo-size-widescreen: 26vh;
	--title-font-size: 6vmax;
	--mission-font-size: 2vmax;
	--mission-font-size-tablet: 3vmax;
	--mission-font-size-mobile: 2.5vmax;
	--title-font-weight: 520;
	--subtitle-font-weight: 600;
	--mission-font-weight: 500;
	--title-container-width: 10vh;
	--mission-container-width: 5vh;
	--gap-1: 5vmax;
	--margin-container: 1.5vmax;
	--margin-top-logo: 4vmax;
	--max-width-mission: 50vmax;
	--copy-window-position: 2vh;
	--primary-background: rgb(228, 226, 226);
	--secondary-background: rgb(15, 16, 41);
	--primary-background-opacity: 0, 0, 0;
	--second-background-opacity: 0, 0, 0;
	--opacity-percent: 0.4;
	--primary-color: rgb(226, 226, 226);
	--secondary-color: rgb(15, 16, 41);
	--gradient-percent: 0.4;
	--margin-container-mobile: 02vh 0vh;
	--screen-ratio: 16 / 9;
	--button-font-size: 20px;
	--button-container-width: 50vh;
	--button-width: 80vw;
	--button-width-desktop: 45vh;
	--button-font-weight: 600;
	--button-font-weight-tablet: 800;
}