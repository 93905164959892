$color_1: var(--secondary-color);
$color_2: var(--primary-color);

/*--------------------------------------
|                Mobile                 |
--------------------------------------*/
.container__button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: var(--button-width);
    gap: 0.5em;
    margin-top: 25px;

}

button {
    background: var(--primary-color);
    color: $color_1;
    border: 0.5vw solid var(--secondary-color);
    border-radius: 3vmax;
    padding: 1vh 0.8vw;
    transition: transform 0.3s, box-shadow 0.3s;
    width: var(--button-width);
    font-weight: var(--button-font-weight);
    padding-right: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 7vh;
    padding-right: 10%;
    font-size: var(--button-font-size);
    max-width: 400px;
    flex-wrap: nowrap;

    &:hover {
        background: var(--secondary-background);
        color: $color_2;
        border: 0.25vw solid var(--primary-color);
        transform: scale(1.1);
        box-shadow: 0 1.6vw 4.9vw rgba(0, 0, 0, 0.2);
    }
}

a {
    text-decoration: none;
}

.button__contact-us {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 400px;
    min-width: 260px;
    transition: background-color 0.3s;


    >.inter__button {
        padding-right: 10px;
    }
}

.button__text-privacy {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: var(--button-font-size);
    text-decoration: none;
    text-wrap: nowrap;


    >.inter__button {
        padding-right: 10px;
    }
}

.button__text-contact-us {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: var(--button-font-size);
    text-decoration: none;

}

.link__button {
    text-decoration: none;
}

.button__privacy {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    max-width: 400px;
    min-width: 260px;
    padding-left: 30px;
    flex-wrap: nowrap;

}

.button__mail {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.logo__privacy {
    height: var(--mail-icon-size);
    margin-right: 1vw;
}

.logo__mail {
    height: var(--mail-icon-size);
    margin-right: 1vw;
}

.inter__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5em;

    >article {
        font-size: 22px;
        font-weight: 300;
        padding-bottom: 3px;
    }

    >div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.button__text {
    padding-bottom: 0.2vmax;
}