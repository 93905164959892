$color_1: var(--primary-color);
$color_2: var(--secondary-color);
$font-family_1: "Nunito", sans-serif;

/*# sourceMappingURL=index.css.map */
html {
	height: 100vh;
	font-size: 1rem;
}

body {
	background: radial-gradient(circle at center, var(--secondary-color), var(--primary-background)) var(--primary-background);
	color: $color_1;
	font-family: $font-family_1;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: auto;
	overflow: hidden;
}

footer {
	color: $color_2;
	margin-bottom: 50px;
}

.section__container {
	margin-top: 5vh;
	height: 95vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.div__container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 78%;
	width: 95%;
	margin: var(--margin-container);
	position: relative;
	overflow: hidden;
	background: var(--secondary-background);
	border-radius: 3vmax;
	flex-direction: column;
	max-height: 90vh;
	padding-bottom: 10%;
}

.container__logo {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: var(--margin-top-logo);
}

.img__logo {
	width: var(--logo-size);
}

.main__page {
	display: flex;
	height: 100%;
	width: 100%;
}

.icon-mail {
	align-items: center;
}

.container__title {
	font-size: var(--title-font-size);
	font-weight: var(--title-font-weight);
	text-align: center;
	color: $color_1;
	margin: var(--margin-container);
}

.paragraph__text {
	font-size: var(--mission-font-size);
}

.container__mission {
	text-align: justify;
	font-size: var(--mission-font-size);
	min-width: 30vmax;
	max-width: 60vmax;
	margin: var(--margin-container);
}

figure {
	padding-top: 0.2vmax;
}

#privacy {
	opacity: 1;
}

#figure__privacy {
	display: flex;
	flex-direction: row-reverse;
}

#privacy-hover {
	opacity: 0;
}

#figure__mail {
	display: flex;
	flex-direction: row-reverse;
}

#mail {
	opacity: 1;
}

#mail-hover {
	opacity: 0;
}

@media screen and (min-aspect-ratio: 13/9) {
	.div__container {
		display: flex;
		align-items: center;
		margin: var(--margin-container);
	}

	.container__logo {
		width: 0vmax;
		width: 35%;
	}

	.img__logo {
		height: var(--logo-size-widescreen);
	}

	.container__title {
		font-size: 8.8vh;
	}

	.container__mission {
		width: var(--container-mission-width-mobile);
		font-size: 3vh;
	}

	#copyright {
		position: auto;
	}

	.logo__mail {
		height: var(--mail-icon-size);
	}

	.logo__mail-hover {
		height: var(--mail-icon-size-hover);
	}

	.logo__privacy {
		height: var(--mail-icon-size);
	}

	.logo__privacy-hover {
		height: var(--mail-icon-size-hover);
	}
}

@media (max-width: 768px) {
	body {
		overflow: hidden;
	}

	.section__container {
		display: flex;
		align-items: flex-start;
	}

	.img__logo {
		height: var(--logo-size-tablet);
	}

	.container__mission {
		font-size: var(--mission-font-size-tablet);
		margin: var(--mission-margin-lr-tablet);
		text-align: center;
		width: var(--container-mission-width-tablet);
	}

	.div__container {
		margin: var(--margin-container-tablet);
		height: 85%;
	}

	#copyright {
		position: fixed;
		bottom: 15px;
		left: 30%;
	}
}

@media (max-width: 480px) {
	.container__mission {
		margin: var(--mission-margin-lr-mobile);
		font-size: var(--mission-font-size-mobile);
		width: var(--container-mission-width-mobile);
		height: 100vh;
	}

	body {
		display: flex;
		justify-content: flex-start;
	}

	#copyright {
		position: fixed;
		bottom: 15px;
		left: 30%;
	}

	.img__logo {
		height: var(--logo-size-mobile);
	}
}